import React, {useContext} from 'react';
import {Timeline} from 'react-twitter-widgets';
import {Seo} from '../../components/common';
import styled from 'styled-components';
import {ThemeContext} from '../../providers/ThemeProvider';

const InfoDiv = styled.div`
  font-size: medium;
  width: 90%;
  color: ${({theme}) => (theme === 'light' ? '#000' : '#fff')};
  padding: 2em;
  text-indent: 0;
  line-height: 1.5em;
`;
const Background = styled.div`
  width: 100%;
  min-height: 70vh;
  background: ${({theme}) => (theme === 'light' ? '#fff' : '#000')};
`;

const Blockquote = styled.blockquote`
  flex: 1;
  width: 100%;
  display: block;
  font-family: 'Helvetica Neue, Roboto, Segoe UI, Calibri', sans-serif;
  font-size: 12px;
  line-height: 16px;
  //border-color: #eee;
  //border-radius: 5px;
  margin: 1em auto;
  padding: 0;
  min-width: 375px;
  max-width: 650px;
  //minHeight: "70vh",
`;

const SocialMedia = ({lang}) => {
  const {theme} = useContext(ThemeContext);
  const isEn = lang === 'en';
  // const commonChrome = 'noheader,nofooter,noborders,noscrollbar,transparent';
  return (
    <>
      <Seo
        title={isEn ? 'Twitter - Tao Jiang' : '蒋韬的推特'}
        description={isEn ? "Tao Jiang's Twitter timeline" : '蒋韬的推特时间线'}
        location={isEn ? '/twitter' : '/cn/twitter'}
        lang={lang}
      />
      <Background theme={theme}>
        <Blockquote>
          <Timeline
            dataSource={{
              sourceType: 'profile',
              screenName: 'TaoJiangScholar',
            }}
            renderError={() => (
              <InfoDiv theme={theme}>
                <div>
                  <p>
                    {isEn
                      ? 'Could not load Twitter content due to browser privacy setting or internet connection.'
                      : '无法加载推特内容，请检查网络连接或浏览器隐私设置。'}
                  </p>
                  {isEn ? (
                    <p>
                      Please check out{' '}
                      <a href="https://twitter.com/taojiangscholar" target="_blank" rel="noreferrer">
                        Tao Jiang on Twitter
                      </a>
                      .
                    </p>
                  ) : (
                    <p>
                      访问
                      <a href="https://twitter.com/taojiangscholar" target="_blank" rel="noreferrer">
                        蒋韬的推特主页
                      </a>
                      .
                    </p>
                  )}
                </div>
              </InfoDiv>
            )}
            options={{
              // chrome: theme === 'light' ? '#ffffff%20' + commonChrome : 'transparent%20' + commonChrome,
              chrome: 'noheader, nofooter, noborders, noscrollbar, transparent',
              dnt: true,
              lang: isEn ? 'en' : 'zh-cn', // https://developer.twitter.com/en/docs/twitter-for-websites/supported-languages
              theme: theme === 'light' ? 'light' : 'dark',
            }}
          />
        </Blockquote>
      </Background>
    </>
  );
};

export default SocialMedia;
