import React from "react";
import SocialMedia from "../subpage_src/components/SocialMedia";
import Layout from "../subpage_src/components/Layout";

const Twitter = () => {
    return (
        <Layout lang={"en"}>
            <SocialMedia lang={"en"}/>
        </Layout>
    )
}

export default Twitter;